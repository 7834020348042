// App.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

import "./App.css";
import QRCode from "qrcode.react";
import {
  MdAdd,
  MdArrowForward,
  MdCamera,
  MdCheck,
  MdCheckBox,
  MdClose,
  MdDelete,
} from "react-icons/md";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";
import moment from "moment";
import Invitation from "./components/Invitation";
import Admin from "./components/Admin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SelectByCode from "./components/SelectByCode";

function App() {
  const [data, setData] = useState({});
  const [postData, setPostData] = useState({
    name: "",
    type: "",
  });
  const [result, setResult] = useState(null);
  const [other, setOther] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [wallet, setWallet] = useState("");
  const [mint, setMint] = useState([]);
  const [mintData, setMintData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempChange, setTempChange] = useState({ name: "", type: "" });
  const [page, setPage] = useState("D");
  const [tempData, setTempData] = useState({ name: "", type: "" });

  const [formData, setFormData] = useState({
    phone_number: "",
    answer: "",
    amount: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const url = "https://admlabz.com/ajparty";
  const access_key_id = "c6c5a0ee-153b-4e63-93c5-42f68319a7a0";
  const key_secret = "j9ALLnH.SLk~OScd-3-kKkpi0T";

  const handleSubmit = async (e) => {
    //e.preventDefault();
    try {
      const response = await axios.post(url + "/phone_numbers", formData);
      console.log(response.data);
      // Optionally, reset the form after successful submission
      alert("completed");
      setFormData({
        phone_number: "",
        answer: "",
      });
    } catch (error) {
      console.error("Error:", error);
      alert("error");
    }
  };

  const API_BASE_URL = url;
  const selectByCode = async (code) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/phone_numbers/select_by_code`,
        { code }
      );
      return response.data;
    } catch (error) {
      console.error("Error selecting data:", error);
      throw error;
    }
  };

  useEffect(() => {
    // Conditionally preventing the page from being scrollable
    if (page !== "D") {
      // Store the original overflowY value
      const originalStyle = window.getComputedStyle(document.body).overflowY;
      // Prevent scrolling
      window.scrollTo(0, 0);
      document.body.style.overflowY = "hidden";

      // Cleanup function to reset the overflowY style when the component unmounts or the page changes
      return () => {
        document.body.style.overflowY = originalStyle;
      };
    }
  }, [page]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Invitation />} />
        <Route path="/:code" element={<SelectByCode />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
