import QRCode from "qrcode.react";
import {
  MdAdd,
  MdArrowForward,
  MdCalendarMonth,
  MdCamera,
  MdCheck,
  MdCheckBox,
  MdClose,
  MdDelete,
  MdHorizontalDistribute,
  MdHorizontalRule,
} from "react-icons/md";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";
import moment from "moment";

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { createICSFile } from "./utils";
export default function Invitation({ code }) {
  const [data, setData] = useState({});
  const [postData, setPostData] = useState({
    name: "",
    type: "",
  });
  const [result, setResult] = useState(null);
  const [other, setOther] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [wallet, setWallet] = useState("");
  const [mint, setMint] = useState([]);
  const [mintData, setMintData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempChange, setTempChange] = useState({ name: "", type: "" });
  const [page, setPage] = useState("D");
  const [tempData, setTempData] = useState({ name: "", type: "" });
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    phone_number: "",
    answer: "",
    amount: 0,
    code: code,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const url = "https://admlabz.com/ajparty";

  const checkCode = async () => {
    setLoading(true);
    try {
      const response = await axios.post(url + "/phone_numbers/select_by_code", {
        code,
      });
      if (response.data.length > 0) {
        setData(response.data[0]);
        setLoading(false);
      } else {
      }
      setError(null);
    } catch (err) {
      setError("Error selecting data");
      console.log(err);
      alert("error");
    }
  };

  const handleSubmit = async (e) => {
    //e.preventDefault();
    let forms = { ...formData };
    forms.amount = JSON.stringify(forms.amount);

    try {
      const response = await axios.post(url + "/updatersvp", forms);

      // Optionally, reset the form after successful submission

      setFormData({
        phone_number: "",
        answer: "",
        amount: 0,
      });
      checkCode();
    } catch (error) {
      console.error("Error:", error);
      alert(JSON.stringify(error));
      alert("error");
    }
  };

  const handleAddToCalendar = () => {
    const event = {
      title: "AJ Birthday Party",
      description: "This is a sample event description.",
      start: "20240630T170000Z", // Format: YYYYMMDDTHHmmssZ (UTC time)
      end: "20240630T210000Z", // Format: YYYYMMDDTHHmmssZ (UTC time)
      location: "9 Appletree Ct. Marlboro, NJ 07746",
    };

    createICSFile(event);
  };

  useEffect(() => {
    const checkCode = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          url + "/phone_numbers/select_by_code",
          { code }
        );
        if (response.data.length > 0) {
          setData(response.data[0]);
          setLoading(false);
        } else {
        }
        setError(null);
      } catch (err) {
        setError("Error selecting data");
        console.log(err);
        alert("error");
      }
    };

    checkCode();
  }, []);

  return loading == true ? (
    <div className="flex flex-col gap-1 justify-center items-center min-h-screen w-full bg-sky-500 pb-20">
      Loading
    </div>
  ) : (
    <div>
      <div className="flex flex-col gap-1 justify-center items-center min-h-screen w-full bg-sky-500 pb-20">
        <div className="absolute top-0 left-0 w-full p-2 flex justify-center items-center text-white font-bold text-lg">
          Welcome {data.name && data.name.toUpperCase()}
        </div>
        <img src="./images/M2.png" className="w-3/4" />
        <div className="text-white font-bold text-5xl mt-1 ">AJ Turns 5</div>
        <div className="text-white font-bold text-lg">
          Come Join on on June 30th at 1pm
        </div>
        <div className="text-gray-200 font-bold text-lg">
          9 Appletree Ct. Marlboro, NJ 07746
        </div>
        {data.answer == "Yes" ? (
          <div className="w-full flex flex-col justify-center items-center  text-white text-lg border-sky-400 pb-2 pt-2">
            <div>See You Soon Pal! </div>
            <button
              className="w-1/2 flex flex-col justify-center items-center p-2 rounded-2xl bg-sky-600"
              onClick={handleAddToCalendar}
            >
              Add to Calendar
              <MdCalendarMonth />
            </button>
          </div>
        ) : (
          <div>
            <div className="w-full flex flex-col justify-around border-b-2 border-t-2  border-sky-400 pb-2 pt-2">
              <div className="w-full flex justify-center items-center text-white font-bold">
                RSVP
              </div>
              <div className="text-gray-100 text-sm flex justify-center items-center">
                Please RSVP by June 25th
              </div>

              <div className="w-full flex justify-around gap-1">
                <button
                  className={`w-3/4 rounded-2xl  p-4 ml-4 mr-4 ${
                    formData.answer == "Yes" ? "bg-sky-600" : "bg-sky-400"
                  }`}
                  onClick={() => setFormData({ ...formData, answer: "Yes" })}
                >
                  <div className="text-white text-xl font-bold">Yes</div>
                </button>
                <button
                  className={`w-3/4 rounded-2xl  p-4 ml-4 mr-4 ${
                    formData.answer == "No" ? "bg-sky-600" : "bg-sky-400"
                  }`}
                  onClick={() => setFormData({ ...formData, answer: "No" })}
                >
                  <div className="text-white text-xl font-bold">No</div>
                </button>
              </div>
            </div>
            <div className="w-full flex justify-center items-center font-bold text-white">
              Amount of People
            </div>
            <div className="flex w-full  border-sky-400 pb-2 justify-center items-center ">
              <button
                className="rounded-3xl p-2 bg-gray-200 w-[50px] h-[50px] mr-2 flex justify-center items-center"
                onClick={() =>
                  setFormData({
                    ...formData,
                    amount:
                      formData.amount >= 1
                        ? (formData.amount -= 1)
                        : formData.amount,
                  })
                }
              >
                <MdHorizontalRule />
              </button>
              <div className="text-white">{formData.amount}</div>
              <button
                className="rounded-3xl p-2 bg-gray-200 ml-2 w-[50px] h-[50px] flex justify-center items-center"
                onClick={() =>
                  setFormData({
                    ...formData,
                    amount:
                      formData.amount >= 0
                        ? (formData.amount += 1)
                        : formData.amount,
                  })
                }
              >
                <MdAdd />
              </button>
            </div>

            <div className="w-full px-4">
              <button className="w-full rounded-2xl bg-sky-400 p-4 ">
                <div
                  className="text-white text-xl font-bold"
                  onClick={handleSubmit}
                >
                  Submit
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
