import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Invitation from "./Invitation";

const SelectByCode = () => {
  const { code } = useParams();
  const [exists, setExists] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const url = "https://admlabz.com/ajparty";
  useEffect(() => {
    const checkCode = async () => {
      try {
        const response = await axios.post(
          url + "/phone_numbers/select_by_code",
          { code }
        );
        if (response.data.length > 0) {
          setExists(true);
          setData(response.data[0]);
        } else {
          setExists(false);
        }
        setError(null);
      } catch (err) {
        setError("Error selecting data");
        console.log(err);
        setExists(false);
      }
    };

    checkCode();
  }, [code]);

  return (
    <div>
      {
        //error && <p style={{ color: "red" }}>{error}</p>
      }

      <Invitation code={code} />
    </div>
  );
};

export default SelectByCode;
