import QRCode from "qrcode.react";
import {
  MdAdd,
  MdArrowForward,
  MdCamera,
  MdCheck,
  MdCheckBox,
  MdClose,
  MdDelete,
  MdKayaking,
  MdSwitchLeft,
} from "react-icons/md";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";
import moment from "moment";

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
export default function Admin() {
  const [data, setData] = useState({});
  const [postData, setPostData] = useState({
    name: "",
    type: "",
  });
  const [result, setResult] = useState(null);
  const [other, setOther] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [wallet, setWallet] = useState("");
  const [mint, setMint] = useState([]);
  const [mintData, setMintData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempChange, setTempChange] = useState({ name: "", type: "" });
  const [page, setPage] = useState("D");
  const [tempData, setTempData] = useState({ name: "", type: "" });
  const inputRef = useRef();

  const [list, setList] = useState([]);

  const [formData, setFormData] = useState({
    phone_number: "",
    answer: "",
    name: "",
    amount: 0,
  });

  const url = "https://admlabz.com/ajparty/";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    //e.preventDefault();
    let forms = { ...formData, code: codeGenerator() };

    try {
      const response = await axios.post(url + "phone_numbers", forms);

      // Optionally, reset the form after successful submission

      setFormData({
        phone_number: "",
        answer: "",
        name: "",
        amount: 0,
      });
      fetchData();
      inputRef.current.focus();
    } catch (error) {
      console.error("Error:", error);
      alert(JSON.stringify(error));
    }
  };

  // Function to make GET request
  const fetchData = async () => {
    try {
      const response = await axios.get(url + "phone_numbers");
      setList(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
      alert("error");
    }
  };

  const codeGenerator = () => {
    let x = [];
    let y = 0;

    let numbersAndLetters = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    while (y < 8) {
      x.push(
        numbersAndLetters[Math.floor(Math.random() * numbersAndLetters.length)]
      );
      y += 1;
    }
    return x.join("");
  };

  const listers = (title, type) => {
    return (
      <div className="flex flex-col justify-center items-center border-gray-300 w-[48%] flex-grow">
        <div className="w-full font-bold flex justify-center items-center">
          {title ? title : "N/A"}
        </div>
        <div className="text-gray-500 text-sm w-full flex justify-center items-center">
          {type}
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-screen h-screen bg-gray-200">
      <div className="absolute top-0 left-0 w-full bg-gray-400 p-4 flex justify-between">
        <div className="flex-1 flex justify-center items-center font-bold text-lg text-white">
          Admin Panel
        </div>
        <MdSwitchLeft size={40} color="white" />
      </div>
      <div className="pt-20 flex flex-col gap-1 text-gray-600 overflow-y-scroll max-h-[50%]  pb-40">
        {list.length < 1 ? (
          <div className="w-full flex justify-center items-center font-bold text-lg ">
            No Contacts
          </div>
        ) : (
          list.map((a, i) => (
            <div
              className="w-screen flex flex-wrap border-t-2  border-gray-400 p-4 items-center justify-center bg-cyan"
              key={i}
            >
              {listers(a.name, "name")}
              {listers(a.phone_number, "Phone Number")}
              {listers(a.answer, "Answer")}
              {listers(a.amount, "Amount")}
              {listers(a.sent, "Status")}
              {listers(a.code, "Code")}
            </div>
          ))
        )}
      </div>
      <div className="border-t-2 border-gray-300 p-4 flex flex-col gap-1">
        <div className="text-lg font-bold flex justify-center items-center">
          Add Contact
        </div>
        <div className="flex justify-around gap-1">
          <div className="font-bold text-md pr-2 flex-1 flex items-center justify-center">
            Name
          </div>
          <input
            className="flex-1 p-2"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            value={formData.name}
            ref={inputRef}
            onClick={(e) => setFormData({ ...formData, name: "" })}
          />
        </div>
        <div className="flex justify-around gap-1">
          <div className="font-bold text-md pr-2 flex-1 flex items-center justify-center">
            Phone Number
          </div>
          <input
            className="flex-1 p-2"
            onChange={(e) =>
              setFormData({ ...formData, phone_number: e.target.value })
            }
            value={formData.phone_number}
            onClick={(e) => setFormData({ ...formData, phone_number: "" })}
          />
        </div>
        <button
          className="w-full px-2 rounded-2xl bg-cyan-600 p-4 text-white mt-1"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div className="border-t-2 border-gray-300 p-4 flex flex-col gap-1">
        Send a message
      </div>
    </div>
  );
}
